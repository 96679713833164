export default class ForgotPasswordController {
  resetPassword = () => {

  }

  constructor($rootScope, $state, AuthenticationService) {
    this.$rootScope = $rootScope;
    this.$state = $state;
    this.AuthenticationService = AuthenticationService;
    this.email = '';
    this.waiting = false;
  }

}

ForgotPasswordController.$inject = ['$rootScope', '$state', 'AuthenticationService'];
