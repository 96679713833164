import angular from 'angular';

import {routes, routing} from './authentication.routes';
import LoginController from './login/login.controller';
import ForgotPasswordController from "./forgot-password/forgot-password.controller";
import AuthenticationService from './authentication.service';
import TwoFactorController from "./two-factor/two-factor.controller";

export default angular.module('app.authentication', [])
  .config(routes)
  .config(routing)
  .controller('LoginController', LoginController)
  .controller('ForgotPasswordController', ForgotPasswordController)
  .controller('TwoFactorController', TwoFactorController)
  .service('AuthenticationService', AuthenticationService)
  .directive('valueMatches', ['$parse', function ($parse) {
    return {
      require: 'ngModel',
      link: function (scope, elm, attrs, ngModel) {
        var originalModel = $parse(attrs.valueMatches),
          secondModel = $parse(attrs.ngModel);
        // Watch for changes to this input
        scope.$watch(attrs.ngModel, function (newValue) {
          ngModel.$setValidity(attrs.name, newValue === originalModel(scope));
        });
        // Watch for changes to the value-matches model's value
        scope.$watch(attrs.valueMatches, function (newValue) {
          ngModel.$setValidity(attrs.name, newValue === secondModel(scope));
        });
      }
    };
  }])
  .name;
