export default class RoleAddController {
  constructor($state, AdministrationService, UIService) {
    this.$state = $state;
    this.Administration = AdministrationService;
    this.UI = UIService;

    this.goForEdit = false;

    this.group = {
      name: '',
      description: '',
      active: true
    };
  }

  createGroup = () => {
    this.Administration.createGroup(this.group).then((group) => {
      this.UI.addToast('Cargo adicionado');
      if (this.goForEdit) {
        this.$state.go('app.administration.roles.edit', {id: group.id});
      } else {
        this.$state.go('app.administration.main');
      }
    })
  }
}

RoleAddController.$inject = ['$state', 'AdministrationService', 'UIService'];
