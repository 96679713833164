export default class AdministrationService {

  constructor(Rolemap, Role, Users, Group, Rolegroup, $q) {
    this.Rolemap = Rolemap;
    this.Role = Role;
    this.Users = Users;
    this.Group = Group;
    this.Rolegroup = Rolegroup;
    this.$q = $q;
  }

  /* Role related functions */

  getGroups = () => {
    let defer = this.$q.defer();
    this.Group.find({}).$promise.then((groups) => {
      defer.resolve(groups);
    });
    return defer.promise;
  };

  getGroupInfo = (roleId) => {
    let defer = this.$q.defer();
    this.Group.findOne({
      filter: {
        where: {
          id: roleId,
          active: true
        },
        include: ['users', {
          relation: 'roles',
          scope: {include: 'feature'}
        }]
      }
    }).$promise.then(r => {
      defer.resolve(r);
    });
    return defer.promise;
  };

  getGroupRoles = (roleId) => {
    let defer = this.$q.defer();
    this.Rolegroup.find({
      filter: {
        where: {
          groupId: roleId
        },
        include: {
          relation: 'role',
          scope: {include: 'feature'}
        }
      }
    }).$promise.then(roles => {
      defer.resolve(roles)
    });
    return defer.promise;
  };

  updateGroup = (group, featuredRoles, users) => {

    let defer = this.$q.defer();
    let roles = [];
    // Ungroup the roles
    featuredRoles.forEach(f => {
      f.roles.forEach(role => {
        roles.push(role);
      });
    });

    this.Group.updateInfo({id: group.id, users: users, roles: roles}).$promise.then((result) => {
      group.edited = new Date();
      group.$save().then(() => {
        defer.resolve();
      });
    });

    return defer.promise;
  };

  groupHasUsers = (id) => {
    let defer = this.$q.defer();
    this.Group.findOne({
      filter: {
        where: {
          id: id
        },
        include: 'users'
      }
    }).$promise.then((res) => {
      if (res.users.length > 0) {
        defer.resolve(true);
      } else {
        defer.resolve(false);
      }
    });
    return defer.promise;
  };

  createGroup = (group) => {
    let defer = this.$q.defer();

    group.edited = new Date();

    this.Group.create(group).$promise.then((data) => {
      defer.resolve(data);
    }).catch(err => {
      defer.reject(err);
    });

    return defer.promise;
  };

  /* User related functions */
  getUsersInRole = (roleId) => {
    let defer = this.$q.defer();
    this.Group.find({
      filter: {
        fields: {id: true},
        where: {
          id: roleId
        },
        include: 'users'
      }
    }).$promise.then(role => {
      let users = [];
      role.forEach(r => {
        r.users.forEach(u => {
          users.push(u);
        })
      });
      defer.resolve(users);
    });
    return defer.promise;
  };

  getUsersNotInRole = (roleId) => {
    let defer = this.$q.defer();

    this.getUsersInRole(roleId).then(users => {
      let ids = _.map(users, u => {
        return u.id;
      });

      this.Users.find({
        filter: {
          where: {
            id: {nin: ids}
          }
        }
      }).$promise.then(list => defer.resolve(list));
    });

    return defer.promise;
  };

  getUsers = () => {
    let defer = this.$q.defer();
    this.Users.find({}).$promise.then((users) => {
      defer.resolve(users);
    });
    return defer.promise;
  };

  getUser = (id) => {
    let defer = this.$q.defer();
    this.Users.findOne({
      filter: {
        where: {
          id: id
        },
        include: 'groups'
      }
    }).$promise.then((user) => {
      defer.resolve(user);
    });
    return defer.promise;
  };

  createUser = (data) => {
    let defer = this.$q.defer();

    // Populate data
    data.blocked = false;
    data.createdAt = new Date();

    this.Users.upsert(data).$promise.then((result) => {
      defer.resolve(result);
    }).catch(err => {
      defer.reject(err);
    });
    return defer.promise;
  };

  removeUser = (user) => {
    let defer = this.$q.defer();

    this.Users.destroyById({id: user.id}).$promise.then((result) => {
      defer.resolve(result);
    }).catch(err => {
      defer.reject(err);
    });
    return defer.promise;
  }
}

AdministrationService.$inject = ['Rolemap', 'Role', 'User', 'Group', 'Rolegroup', '$q'];
