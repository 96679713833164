export default class LayoutController {
  constructor($rootScope, AuthenticationService, AuthorizationService, $state, UIService) {

    this.AuthenticationService = AuthenticationService;
    this.AuthorizationService = AuthorizationService;
    this.$state = $state;
    this.UI = UIService;

    $rootScope.$on('$receivedServerStatus', (evt, data) => {
      this.status = data;
      $rootScope.status = data;
    });

    this.menuItems = [];

    this.user = AuthenticationService.getUser();

    this.isAuthenticated = () => {
      AuthenticationService.isAuthenticated();
    };

    this.parseMenu();
  }

  logout = () => {
    this.AuthenticationService.logout();
  };

  parseMenu = () => {
    const jsonMenu = require('../sidenav.json');
    // Getting list of all states, so that role will be loaded from states...
    let states = this.$state.get();
    states = _.filter(states, s => {
      // Ignore abstract routes and placeholders
      return s.hasOwnProperty('template') && s.hasOwnProperty('controller');
    });

    // Iterate the sidebar options through the states, to get the required role for it
    let menu = [];
    jsonMenu.forEach(section => {
      if (section.hasOwnProperty('sections')) {
        let k = [];
        section.sections.forEach(o => {
          let s = _.find(states, {name: o.state});
          if (angular.isDefined(s)) {
            if (s.hasOwnProperty('role')) {
              if (this.AuthorizationService.canPerform(s.role))
                k.push(o)
            } else k.push(o);
          } else {
            // Do nothing... :)
          }
        });
        if (k.length > 0) {
          section.sections = k;
          menu.push(section);
        }
      } else {
        let s = _.find(states, {name: section.state});
        if (angular.isDefined(s)) {
          if (s.hasOwnProperty('role')) {
            if (this.AuthorizationService.canPerform(s.role))
              menu.push(section)
          } else menu.push(section);
        } else {
          // Do nothing... :)
        }
      }
    });
    this.menuItems = menu;
  }
}

LayoutController.$inject = ['$rootScope', 'AuthenticationService', 'AuthorizationService', '$state', 'UIService'];
