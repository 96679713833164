export default class ManagementController {
  constructor($rootScope, $state, $filter, UIService, AdministrationService, AuthenticationService) {
    this.$rootScope = $rootScope;
    this.$state = $state;
    this.$filter = $filter;
    this.UI = UIService;
    this.Administration = AdministrationService;
    this.Authentication = AuthenticationService;

    this.userData = {
      data: [],
      filter: '',
      pagination: {
        perPage: 10,
        current: 0,
        previous: () => {
          if (this.userData.pagination.current > 0)
            this.userData.pagination.current--;
        },
        onFilter: () => {
          console.log(this.userData.filter);
          const filteredArray = $filter('filter')(this.userData.data, this.userData.filter);
          const afterFilter = Math.ceil(filteredArray.length / this.userData.pagination.perPage) - 1;
          if (afterFilter < this.userData.pagination.current) {
            this.userData.pagination.current = afterFilter;
          }
        },
        next: () => {
          if (this.userData.pagination.current < this.userData.pagination.total() - 1)
            this.userData.pagination.current++;
        },
        total: () => {
          const filteredArray = $filter('filter')(this.userData.data, this.userData.filter);
          return Math.ceil(filteredArray.length / this.userData.pagination.perPage);
        }
      }
    };
    this.groupData = {
      data: [],
      filter: "",
      pagination: {
        perPage: 10,
        current: 0,
        previous: () => {
          if (this.userData.pagination.current > 0)
            this.userData.pagination.current--;
        },
        onFilter: () => {
          const filteredArray = $filter('filter')(this.groupData.data, this.userData.filter);
          const afterFilter = Math.ceil(filteredArray.length / this.userData.pagination.perPage) - 1;
          if (afterFilter < this.userData.pagination.current) {
            this.userData.pagination.current = afterFilter;
          }
        },
        next: () => {
          if (this.userData.pagination.current < this.userData.pagination.total() - 1)
            this.userData.pagination.current++;
        },
        total: () => {
          const filteredArray = $filter('filter')(this.groupData.data, this.userData.filter);
          return Math.ceil(filteredArray.length / this.userData.pagination.perPage);
        }
      }
    };
    this.loaded = false;

    this.loadInfo();
  }

  loadInfo = () => {
    this.loaded = false;
    this.Administration.getUsers().then((users) => {
      this.Administration.getGroups().then((groups) => {
        this.userData.data = users;
        this.groupData.data = groups;
        this.loaded = true;
      })
    });
  };

  isCurrentUser = (user) => {
    return this.Authentication.getId() === user.id;
  };

  blockUser = (user) => {
    if (user.blocked || this.Authentication.getId() === user.id) return;
    let confirm = this.UI.showConfirm('Deseja bloquear utilizador?');
    confirm.then(() => {
      // OK
      user.blocked = true;
      user.$save().then(() => {
        this.UI.addToast('Utilizador bloqueado');
      });
    }).catch(() => {
      // Cancel
    });
  };

  blockCondition = (user) => {
    return user.blocked || this.Authentication.getId() === user.id;
  };

  removeUser = (user) => {
    let dialog = this.UI.showConfirm('Deseja remover utilizador ' + user.name + ' do sistema?');
    dialog.then(() => {
      this.Administration.removeUser(user).then((result) => {
        let index = this.userData.data.indexOf(user);
        this.userData.data.splice(index, 1);
        let snackbar = this.UI.addSnackbar('Utilizador removido', 'Anular', true);
        snackbar.then((res) => {
          if (res) {
            this.Administration.createUser(user).then((res) => {
              this.userData.data.splice(index, 0, user);
            });
          }
        })
        //this.loadInfo();
      });
    });
  }
};

ManagementController.$inject = ['$rootScope', '$state', '$filter', 'UIService', 'AdministrationService', 'AuthenticationService'];
