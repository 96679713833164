export default class LoginController {
  constructor($rootScope, $state, AuthenticationService, UIService) {
    this.$rootScope = $rootScope;
    this.$state = $state;
    this.Authentication = AuthenticationService;
    this.UI = UIService;
    this.email = '';
    this.password = '';
    this.error = '';
    this.waiting = false;
  }

  performLogin = () => {
    this.waiting = true;
    this.Authentication.login(this.email, this.password).then((res) => {
      this.waiting = false;
      if (res.twofactor) {
        this.$state.go('auth.two-factor', {allow: true});
      } else {
        this.$state.go('app.dashboard');
      }
    }).catch((err) => {
      this.waiting = false;
      switch (err.data.error.details) {
        case 'LOGIN_FAILED':
          this.UI.addToast('Utilizador ou palavra-passe errados');
          break;
        case 'LOGIN_BLOCKED':
          this.UI.addToast('Utilizador bloqueado');
          break;
      }
    });
  }
}

LoginController.$inject = ['$rootScope', '$state', 'AuthenticationService', 'UIService'];
