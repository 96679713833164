export function routes($stateProvider) {
  $stateProvider
    .state('auth.login', {
      url: '/login',
      title: 'Iniciar Sessão',
      template: require('./login/login.view.html'),
      controller: 'LoginController',
      controllerAs: 'vm',
    })
    .state('auth.forgot-password', {
      url: '/forgot-password',
      title: 'Esqueceu-se da Password',
      template: require('./forgot-password/forgot-password.view.html'),
      controller: 'ForgotPasswordController',
      controllerAs: 'vm',
    })
    .state('auth.two-factor', {
      url: '/verify',
      title: 'Confirmar acesso',
      template: require('./two-factor/two-factor.view.html'),
      controller: 'TwoFactorController',
      controllerAs: 'vm',
      params: {
        allow: false
      }
    })
}

export function routing($urlRouterProvider, $locationProvider) {
  $locationProvider.html5Mode(true);
  $urlRouterProvider.otherwise('/');
}

routing.$inject  = ['$urlRouterProvider', '$locationProvider'];
routes.$inject = ['$stateProvider'];
