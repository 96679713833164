export default class AuthenticationService {
  constructor($rootScope, Users, LoopBackAuth, $q, $state) {
    this.$rootScope = $rootScope;
    this.Users = Users;
    this.LoopBackAuth = LoopBackAuth;
    this.$q = $q;
    this.user = {};
    this.allowed = {
      access: false
    };
    this.$state = $state;

    if (localStorage.getItem('user') && this.isAuthenticated()) {
      this.allowed = JSON.parse(localStorage.getItem('allowed'));
      this.user = JSON.parse(localStorage.getItem('user'));
      this.getUserInfo();
    }
  }

  // Attempt login with given email and password
  login = (email, password) => {
    let deferred = this.$q.defer();
    this.Users.login({rememberMe: true}, {email, password}).$promise.then((res) => {
      this.Users.findById({
        id: res.user.id, filter: {
          include: {
            relation: 'groups',
            scope: {
              include: {
                relation: 'rolegroup',
                scope: {
                  where: {
                    active: true
                  },
                  include: {
                    relation: 'role'
                  }
                }
              }
            }
          }
        }
      }).$promise.then(user => {
        this.allowed.access = !user.twofactor;
        this.user = user;
        localStorage.setItem('user', JSON.stringify(this.user));
        localStorage.setItem('allowed', JSON.stringify(this.allowed));
        deferred.resolve(this.user);
      });
    }).catch((err) => {
      deferred.reject(err);
    });
    return deferred.promise;
  };

  // Logouts user and invalidates access token in the server
  logout = () => {
    localStorage.removeItem('user');
    this.Users.logout().$promise.then((res) => {
      this.clearUser();
      location.reload(true); // Solution proposed by @antonio.gomes, this fixes the bug that requires F5 to reload jQuery
    }).catch((err) => {
      // In case of error ignore, since it will be catched by the Authentication Handler
    });
  };

  // Clears cache that saves user data
  clearUser = () => {
    this.LoopBackAuth.clearUser();
    this.LoopBackAuth.clearStorage();
    this.allowed.access = false;
    localStorage.removeItem('user');
    localStorage.removeItem('allowed');
  };

  // Get authenticated user access token
  getToken = () => {
    return this.LoopBackAuth.accessTokenId;
  };

  // Get authenticated user Id
  getId = () => {
    return this.user.id;
  };

  // Retrieve user information in cache
  getUser = () => {
    return this.user;
  };

  // Retrieve user information from the server
  getUserInfo = () => {
    this.Users.findById({
      id: this.user.id, filter: {
        include: {
          relation: 'groups',
          scope: {
            include: {
              relation: 'rolegroup',
              scope: {
                where: {
                  active: true
                },
                include: {
                  relation: 'role'
                }
              }
            }
          }
        }
      }
    }).$promise.then(user => {
      this.user = user;
      localStorage.setItem('user',JSON.stringify(this.user));
    }).catch(err => {
      this.logout();
      defer.reject(err);
    });
  };

  // Ask server to validate the access token in cache
  isAuthenticated = () => {
    return this.Users.isAuthenticated();
  };

  // Updates specified field of user with given value
  update = (field, value) => {
    let defer = this.$q.defer();
    this.user[field] = value;
    this.user.$save().then(r => {
      this.getUserInfo();
      defer.resolve(r);
    }).catch(err => defer.reject(err));
    return defer.promise;
  };

  // Tests 2FA token, if result it's true, enable for user
  twoFactorTest = (_token) => {
    let defer = this.$q.defer();
    let token = Number(_token);
    this.Users.verifyToken({token: token}).$promise.then(res => {
      if (res.result) {
        defer.resolve(true);
        this.update('twofactor', true);
      } else {
        defer.resolve(false);
      }
    }).catch(err => defer.reject(err));
    return defer.promise;
  };

  // Checks if user token is valid, and enables access to app
  twoFactor = (_token) => {
    let defer = this.$q.defer();
    let token = Number(_token);
    this.Users.verifyToken({token: token}).$promise.then(res => {
      if (res.result) {
        this.allowed.access = true;
        localStorage.setItem('allowed',JSON.stringify(this.allowed));
        defer.resolve(true);
      } else {
        defer.resolve(false);
      }
    }).catch(err => defer.reject(err));
    return defer.promise;
  };

  // If access is enabled (Enabled automatically if account has 2FA turned off)
  isAllowed = () => {
    return this.allowed.access;
  }

}

AuthenticationService.$inject = ['$rootScope', 'User', 'LoopBackAuth', '$q', '$state'];
