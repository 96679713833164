export default class TwoFactorController {
  constructor($scope, $state, $stateParams, AuthenticationService, UIService) {
    this.$scope = $scope;
    this.$state = $state;
    this.Authentication = AuthenticationService;
    this.UI = UIService;
    this.token = '';
    this.error = '';
    this.waiting = false;

    let allow = $stateParams.allow || false;
    if (!allow) {
      AuthenticationService.clearUser();
      this.$state.go('auth.login');
    }

  }

  verifyToken = () => {
    if (this.$scope.verifyToken.$invalid) { return ; }
    this.waiting = true;
    this.Authentication.twoFactor(this.token).then((res) => {
      this.waiting = false;
      if (res) {
        this.$state.go('app.dashboard');
      } else {
        this.UI.addToast('Código de verificação inválido');
      }
    }).catch(err => {
      this.waiting = false;
      this.UI.addToast('Código de verificação inválido');
    });
  };
}

TwoFactorController.$inject = ['$scope', '$state', '$stateParams', 'AuthenticationService', 'UIService'];
