let jrQrcode = require('jr-qrcode');

export default class ProfileController {
  constructor($timeout, $rootScope, $filter, AuthenticationService, UIService) {
    this.AuthenticationService = AuthenticationService;
    this.UI = UIService;
    $rootScope.$on('$receivedServerStatus', (evt, data) => {
      this.description = data.description;
    });
    this.user = AuthenticationService.getUser();

    // Update date difference in user profile box
    $timeout(() => {
      this.user.dateDiff = $filter('dateDiff')(this.user.lastLogin, false);
    }, 5 * 1000);
    this.user.dateDiff = $filter('dateDiff')(this.user.lastLogin, false);
  }

  // Form for user password change
  changePassword = () => {
    let options = {
      template: require('./change-password.html'),
      controller: ['$scope', '$dialog', function ($scope, $dialog) {

      }]
    };
    let modal = this.UI.showDialog(options);
  };

  // Form to enable 2FA
  setTwoFactor = () => {
    if (this.user.twofactor) {
      this.UI.showConfirm('Deseja desativar autenticação 2 passos?').then(res => {
        if (res) {
          this.AuthenticationService.update('twofactor', false).then(this.UI.addToast('Autenticação 2 passos desativada'));
          this.user.twofactor = false;
        }
      });
    } else {

      let self = this;

      let options = {
        template: require('./two-factor.html'),
        controller: ['$scope','$dialog', function($scope, $dialog) {
          $scope.hasError = false;
          $scope.verify = "";

          $scope.validator = self.user.validator;

          $scope.$watch('verify', function(val, old) {
            if (val.length > 0)
              $scope.hasError = false;
          });

          $scope.drawQRCode = function() {
            let authUrl = 'otpauth://totp/' + self.user.email + '?secret=' + self.user.validator + '&issuer=' + self.description + '&algorithm=SHA1&digits=6&period=30';
            return jrQrcode.getQrBase64(authUrl, {
              padding: 0,
              width: 180,
              height: 180,
              correctLevel: QRErrorCorrectLevel.M
            });
          };

          $scope.cancel = function() {
            $dialog.dismiss('cancel');
          };

          $scope.ok = function() {
            self.AuthenticationService.twoFactorTest($scope.verify).then(res => {
              if (res) {
                $dialog.close(true);
              } else {
                $scope.hasError = true;
                $scope.verify = "";
              }
            });
          }
        }]
      };

      this.UI.showDialog(options).then((res) => {
        if (res) {
          this.UI.addToast('Autenticação 2 passos ativada');
          this.user.twofactor = true;
        }
      });
    }
  };
}

ProfileController.$inject = ['$timeout', '$rootScope', '$filter', 'AuthenticationService', 'UIService'];
