import 'propellerkit-range-slider';
let noUiSlider = require('nouislider');
import angular from 'angular';

export default angular.module('app.interface.slider', [])
  .directive('slider', () => ({
    restrict: 'A',
    require: 'ngModel',
    scope: {
      options: '=',
      handles: '=?'
    },
    link: (scope, element, attrs, ngModel) => {
      let sliderEl = element.parent().hasClass('pmd-slider') ? element.parent() : element;
      sliderEl.addClass('pmd-range-slider');
      let slider = noUiSlider.create(sliderEl[0], scope.options);

      slider.on('slide', (val) => {
        ngModel.$setViewValue(val);
        ngModel.$render();
      });
    }
  }))
  .run(['$rootScope', ($rootScope) => {
    $rootScope.uiModules = $rootScope.uiModules || [];
    let properties = {
      name: 'Slider',
      description: 'Make selections from a range of values.',
      version: '1.0.0'
    };
    if ($rootScope.uiModules.indexOf(properties) === -1) {
      $rootScope.uiModules.push(properties);
    }
  }])
  .name;
