import angular from 'angular';

export default angular.module('app.interface.progress', [])
  .directive('pmdSpinner', () => ({
    replace: true,
    template: () => "" +
      "<svg class='pmd-spinner' width='64px' height='64px' viewBox='0 0 66 66' xmlns='http://www.w3.org/2000/svg'>\n" +
      "   <circle class='path' fill='none' stroke-width='6' stroke-linecap='round' cx='33' cy='33' r='30'></circle>\n" +
      "</svg>"
  }))
  .directive('pmdProgress', () => ({
    replace: true,
    transclude: true,
    scope: {
      total: '=?',
      value: '=?',
    },
    link: function(scope, element, attrs) {
      scope.isIndeterminate = attrs.hasOwnProperty('indeterminate') || false;
    },
    template: () => "" +
      "<div class='pmd-progress'>" +
      "  <div ng-if='!isIndeterminate' class='determinate' style='width: {{value/total * 100}}%'></div>" +
      "  <div ng-if='isIndeterminate' class='indeterminate'></div>" +
      "</div>"
  }))
  .run(['$rootScope', ($rootScope) => {
    $rootScope.uiModules = $rootScope.uiModules || [];
    let properties = {
      name: 'Progress',
      description: 'Spinner & Progress Bar',
      version: '1.0.1'
    };
    if ($rootScope.uiModules.indexOf(properties) === -1) {
      $rootScope.uiModules.push(properties);
    }
  }])
  .name;
