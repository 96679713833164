import angular from 'angular';
import {routes} from './administration.routes'

import ManagementController from './main/main.controller';
import UserAdminController from './main/users/add/add.controller';
import UserEditAdminController from './main/users/edit/edit.controller';
import RoleEditController from './main/roles/edit/edit.controller';
import AdministrationService from "./administration.service";
import RoleAddController from "./main/roles/add/add.controller";
import LoggingController from "./logging/logging.controller";
import SystemController from "./system/system.controller";

export default angular.module('app.administration', [])
  .config(routes)
  .controller('ManagementController', ManagementController)
  .controller('SystemController', SystemController)
  .controller('LoggingController', LoggingController)
  .controller('UserAdminController', UserAdminController)
  .controller('RoleEditController', RoleEditController)
  .controller('RoleAddController', RoleAddController)
  .controller('UserEditAdminController', UserEditAdminController)
  .service('AdministrationService', AdministrationService)
  .name;
